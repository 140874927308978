const React = require('react');
const { forwardRef } = require('react');
const { bool, func, node, shape, string, number } = require('prop-types');
const classnames = require('classnames');
const Image = require('../../image');
const colornames = require('../../../lib/colornames');

const namespace = 'ui-pdp-thumbnail';

const Thumbnail = forwardRef(
  (
    {
      dataTestId,
      className,
      children,
      blocked,
      href,
      label,
      ariaLabel,
      modifier,
      picture,
      onClick,
      subtitle,
      minWidth,
      maxWidth,
      ...rest
    },
    ref,
  ) => {
    const classes = classnames(namespace, className, {
      [`${namespace}--${modifier}`]: modifier,
    });

    const thumbnailProps = { href, modifier: 'link', className: classes, onClick };

    const content = picture ? (
      <div className={classnames(`${namespace}__picture`, picture.className)}>
        <Image alt="" {...picture} {...rest} aria-hidden="true" />
      </div>
    ) : (
      label && (
        <div
          className={classnames(
            { [`${namespace}__container`]: subtitle },
            { [`${namespace}__container__label`]: label },
          )}
        >
          <p className={classnames(`${namespace}__label`)} {...rest}>
            {label}
          </p>
          {subtitle && (
            <p className={classnames(`${namespace}__container__subtitle`, colornames(subtitle.color))} {...rest}>
              {subtitle.text}
            </p>
          )}
        </div>
      )
    );

    const CustomTag = blocked ? 'div' : 'a';

    return (
      <CustomTag
        role={blocked ? 'presentation' : undefined}
        {...thumbnailProps}
        ref={ref}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        style={{ minWidth, maxWidth }}
      >
        {content}
        {children}
      </CustomTag>
    );
  },
);

Thumbnail.propTypes = {
  children: node,
  className: string,
  blocked: bool,
  href: string,
  label: string,
  maxWidth: number,
  minWidth: number,
  modifier: string,
  picture: shape({
    id: string.isRequired,
    lazyload: bool,
    src: string.isRequired,
    src2x: string,
  }),
  onClick: func,
  dataTestId: string,
  subtitle: shape({
    color: string.isRequired,
    text: string.isRequired,
  }),
  ariaLabel: string,
};

Thumbnail.defaultProps = {
  children: null,
  className: '',
  blocked: false,
  href: null,
  label: null,
  modifier: null,
  picture: null,
  onClick: () => {},
  dataTestId: null,
  subtitle: null,
  ariaLabel: null,
};

module.exports = Thumbnail;
