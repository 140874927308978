const React = require('react');
const { number, string } = require('prop-types');
const { Placeholder, Line } = require('../../placeholder');

const VariationsSmallPlaceholder = ({ rows, className }) => (
  <Placeholder>
    <Line height="12px" className={className} />
    {rows === 2 && <Line height="10px" className={className} />}
  </Placeholder>
);

VariationsSmallPlaceholder.propTypes = { rows: number, className: string };

VariationsSmallPlaceholder.defaultProps = { rows: 1, className: null };

module.exports = VariationsSmallPlaceholder;
