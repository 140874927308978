const React = require('react');
const { number, shape, string } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const Price = require('../../price');

const namespace = 'ui-pdp-variations';

const ProductDetails = ({ price, stock, modifier, num_font_size }) => (
  <>
    {price && (
      <Price
        className={`${namespace}__modal__variant__price ${namespace}__box__variant__price`}
        bold
        value={price.value}
        currencySymbol={price.currency_symbol}
        size="small"
        num_font_size={num_font_size}
      />
    )}
    {stock && (
      // Run into StyledLabel
      <p className={colornames(stock, classnames({ [`${namespace}__${modifier}`]: modifier }))}>{stock.text}</p>
    )}
  </>
);

ProductDetails.propTypes = {
  price: shape({ value: number, currency_symbol: string }),
  stock: shape({ text: string, color: string }),
  modifier: string,
  num_font_size: number,
};

ProductDetails.defaultProps = {
  price: null,
  stock: null,
  modifier: null,
  num_font_size: null,
};

module.exports = ProductDetails;
