/* eslint-env browser */

const React = require('react');
const { useEffect, useState, useCallback, useRef } = require('react');
const { func, node, shape, arrayOf, string, bool, number } = require('prop-types');
const classnames = require('classnames');
const uniq = require('lodash/uniq');

const ActionRow = require('../action-row');
const HistoryService = require('../../services/history');
const getPictureConfig = require('./lib/get-picture-config');
const getAttributesParam = require('./lib/get-attributes-param');

const VariationsWrapper = require('./containers/variations-wrapper');
const { dispatchSellerPixelsInfo, getFormattedTrackingData } = require('../../utils/mercadoShopsTracking');
const { runSchedulerTask } = require('../../utils/validators');

const namespace = 'ui-pdp-variations';

const VariationsMobile = ({
  id,
  app,
  filters,
  modal_type,
  actionComponent: ActionComponent,
  apply_action: applyAction,
  className,
  pickers,
  selected_attributes,
  title,
  picture_config,
  variationsDetails,
  platform,
  actionSelected,
  button_quantity,
  buyNowAction,
  selectedQuantity,
  fetchVariationsDetails,
  onSelectVariation,
  requestKey,
  shopsDefaultTrackingData,
  variation_id,
  has_representative_pickers,
  form,
  ms_store,
  buyBoxOffers,
  promotions,
  px_variant_id,
}) => {
  const [isErrorVariation, setIsErrorVariation] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [localKey, setLocalKey] = useState(null);

  const containerRef = useRef();

  const handleFetchVariationsDetails = useCallback(() => {
    const ids = [];

    pickers.forEach(picker =>
      picker.products.forEach(product => {
        if (picker.has_details) {
          ids.push(product.id);
        }
      }),
    );
    if (ids.length > 0 && fetchVariationsDetails) {
      fetchVariationsDetails(uniq(ids).join(','));
    }
  }, [pickers, fetchVariationsDetails]);

  const openModal = useCallback(e => {
    if (e) {
      if (!e.ctrlKey && !e.metaKey) {
        e.preventDefault();
        setIsOpenModal(true);
        handleFetchVariationsDetails();
      }
    } else {
      setIsOpenModal(true);
      setIsErrorVariation(true);
      handleFetchVariationsDetails();
    }
  }, [handleFetchVariationsDetails]);

  const dispatchShopsVariationsTrackingData = useCallback(() => {
    if (shopsDefaultTrackingData && window.tracking_mediator) {
      const shopsTrackingData = getFormattedTrackingData(shopsDefaultTrackingData, variation_id);
      dispatchSellerPixelsInfo(window.tracking_mediator, shopsTrackingData);
    }
  }, [shopsDefaultTrackingData, variation_id]);

  useEffect(() => {
    const showError = pickers.find(picker => picker.error_message && !picker.error_message.is_deferred);
    const container = containerRef.current;

    if (showError && localKey !== requestKey && !isOpenModal) {
      if (container) {
        const margin = 20;
        window.scrollTo({ top: container.offsetTop - margin, behavior: 'smooth' });
      }
      openModal();
    }
    dispatchShopsVariationsTrackingData();
  }, [pickers, isOpenModal, localKey, openModal, requestKey, dispatchShopsVariationsTrackingData]);

  const hideModal = () => {
    setLocalKey(requestKey);
    setIsErrorVariation(false);
    setIsOpenModal(false);
    runSchedulerTask(() => {
      HistoryService.deleteHash();
    }, 'background');
  };

  const handleApplyData = (selectedProduct, selectedAttributes, selectedProductHistoryData) => {
    onSelectVariation(selectedProduct, {
      attributes: getAttributesParam(selectedAttributes),
      quantity: selectedQuantity,
      platform,
      ms_store,
    });

    if (selectedProductHistoryData) {
      const { path, state } = selectedProductHistoryData;

      runSchedulerTask(() => {
        HistoryService.replaceProductAndParam(path, state, 'quantity', selectedQuantity);
      }, 'background');
    }
    hideModal();
  };

  const extraMargin = promotions && buyBoxOffers;

  return (
    <div
      className={classnames(
        { [`${namespace}__container`]: !ActionComponent },
        className,
        extraMargin && `${namespace}__container-bbc`,
      )}
      ref={containerRef}
    >
      {ActionComponent && title ? (
        <ActionComponent title={title.text} onClick={e => openModal(e)} />
      ) : (
        pickers.map(picker => {
          const selected = picker.products.find(product => product.selected);

          if (isErrorVariation && picker.error_message) {
            return (
              <ActionRow
                onClick={openModal}
                key={picker.id}
                className={classnames(namespace)}
                label={picker.label ? picker.label.text : null}
                selected={picker.error_message.selected_option}
                thumbnail={selected ? getPictureConfig(selected) : null}
                disabled={picker.disabled}
              />
            );
          }
          return (
            <ActionRow
              onClick={openModal}
              key={picker.id}
              className={classnames(namespace)}
              label={picker.label ? picker.label.text : null}
              selected={selected ? selected.label : picker.selected_option}
              thumbnail={selected ? getPictureConfig(selected) : null}
              disabled={picker.disabled}
              isErrorVariation={isErrorVariation}
              errorMessage={selected ? selected.error_message : picker.error_message}
            />
          );
        })
      )}
      <VariationsWrapper
        id={id}
        app={app}
        filters={filters}
        modalType={modal_type}
        pickers={pickers}
        visible={isOpenModal}
        selected_attributes={selected_attributes}
        variationsDetails={variationsDetails}
        picture_config={picture_config}
        applyAction={applyAction}
        submit={handleApplyData}
        onClose={hideModal}
        title={title}
        platform={platform}
        actionSelected={actionSelected}
        buttonQuantity={button_quantity}
        isErrorVariation={isErrorVariation}
        buyNowAction={buyNowAction}
        hasRepresentativePickers={has_representative_pickers}
        form={form}
        msStore={ms_store}
        px_variant_id={px_variant_id}
      />
    </div>
  );
};

VariationsMobile.propTypes = {
  id: string.isRequired,
  app: string.isRequired,
  filters: string,
  modal_type: string.isRequired,
  apply_action: shape().isRequired,
  actionComponent: node,
  className: string,
  selected_attributes: shape(),
  title: shape({
    title: string,
  }),
  onSelectVariation: func.isRequired,
  fetchVariationsDetails: func,
  pickers: arrayOf(
    shape({
      id: string,
      hasDetails: bool,
      label: shape({
        color: string,
        text: string,
      }),
      products: arrayOf(
        shape({
          id: string,
          disabled: bool,
          label: shape({
            color: string,
            text: string,
          }),
          picture: shape({
            id: string,
            src: string.isRequired,
            src2x: string,
          }),
          selected: bool,
        }),
      ),
    }),
  ).isRequired,
  selectedQuantity: number,
  variationsDetails: shape({
    products: arrayOf(
      shape({
        price: shape({
          currency_symbol: string,
          value: number,
        }),
        stock: shape({
          color: string,
          text: string,
        }),
      }),
    ),
  }),
  picture_config: shape().isRequired,
  shopsDefaultTrackingData: shape({
    item_id: string,
    currency: string,
    price: number,
    title: string,
  }),
  variation_id: string,
  platform: string,
  requestKey: number,
  actionSelected: shape(),
  button_quantity: shape({
    max_quantity: number,
    selected_value: number,
  }),
  buyNowAction: shape({}),
  has_representative_pickers: bool,
  ms_store: string,
  form: shape({}),
  buyBoxOffers: bool,
  promotions: bool,
  px_variant_id: string,
};

VariationsMobile.defaultProps = {
  filters: null,
  actionComponent: null,
  selected_attributes: null,
  title: null,
  className: '',
  fetchVariationsDetails: null,
  selectedQuantity: 1,
  variationsDetails: null,
  variation_id: null,
  shopsDefaultTrackingData: null,
  platform: null,
  requestKey: null,
  actionSelected: null,
  button_quantity: null,
  ms_store: '',
  buyBoxOffers: null,
  promotions: null,
  px_variant_id: null,
};

module.exports = VariationsMobile;
