const React = require('react');
const PropTypes = require('prop-types');
const Add16 = require('@andes/icons/Add16');
const Substract16 = require('@andes/icons/Substract16');

const { number, func, string } = PropTypes;

const namespace = 'ui-pdp-variations__modal__quantity__selector';

const QuantityButton = ({
  selected_value: value,
  selectQuantity,
  min_quantity: min_quantity_raw,
  max_quantity,
  add,
  substract,
}) => {
  const min_quantity = min_quantity_raw || 1;

  const decrement = () => {
    selectQuantity(value > min_quantity ? value - 1 : min_quantity);
  };

  const increment = () => {
    selectQuantity(value < max_quantity ? value + 1 : max_quantity);
  };
  return (
    <div className={namespace}>
      <button
        type="button"
        className={`${namespace}-input__modifier ${namespace}-input__modifier--left`}
        onClick={decrement}
        data-testid="quantity-substract"
      >
        {substract && <span className="clipped">{substract}</span>}
        <div aria-hidden="true">
          <Substract16 role="img" alt="" />
        </div>
      </button>
      <span className={`${namespace}-input__screen`}>{value}</span>
      <button
        type="button"
        className={`${namespace}-input__modifier ${namespace}-input__modifier--right`}
        onClick={increment}
        data-testid="quantity-add"
      >
        {add && <span className="clipped">{add}</span>}
        <div aria-hidden="true">
          <Add16 role="img" alt="" />
        </div>
      </button>
    </div>
  );
};

QuantityButton.propTypes = {
  max_quantity: number.isRequired,
  min_quantity: number.isRequired,
  selected_value: number.isRequired,
  selectQuantity: func.isRequired,
  add: string,
  substract: string,
};

QuantityButton.defaultProps = {
  add: null,
  substract: null,
};

module.exports = React.memo(QuantityButton);
