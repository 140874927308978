const React = require('react');
const { arrayOf, func, number, shape, string, bool } = require('prop-types');
const { loadable } = require('nordic/lazy');
const classNames = require('classnames');
const AndesForm = require('@andes/form');
const StaticPropsContext = require('../../context/static-props');
const { ACTION_BUYBOX_TYPES } = require('./utils/action-types');
const { mapOffsetsToCamelCase } = require('./utils/mapOffsetsToCamelCase');
const { generateKeyToComponent } = require('../../../utils/validators');

const { useContext } = React;
const Action = loadable(() => import('./action'));

const namespace = 'ui-pdp-actions';

const getActionHierarchy = type => {
  switch (type) {
    case ACTION_BUYBOX_TYPES.ACTION_TYPE_PRIMARY:
      return ACTION_BUYBOX_TYPES.ACTION_HIERARCHY_LOUD;
    case ACTION_BUYBOX_TYPES.ACTION_TYPE_SECONDARY:
      return ACTION_BUYBOX_TYPES.ACTION_HIERARCHY_QUIET;
    case ACTION_BUYBOX_TYPES.ACTION_TYPE_TERTIARY:
      return ACTION_BUYBOX_TYPES.ACTION_HIERARCHY_TRANSPARENT;
    case ACTION_BUYBOX_TYPES.ACTION_TYPE_ACTION_ROW:
      return ACTION_BUYBOX_TYPES.ACTION_HIERARCHY_ROW;
    case ACTION_BUYBOX_TYPES.ACTION_ADDITIONAL_INFO:
      return ACTION_BUYBOX_TYPES.ACTION_HIERARCHY_TRANSPARENT;
    default:
      return ACTION_BUYBOX_TYPES.ACTION_HIERARCHY_LOUD;
  }
};

const Actions = ({
  selectedQuantity,
  actions,
  form,
  size,
  content_featured,
  onSubmit,
  showQuantityError,
  showVariationsError,
  triggerSequencer,
  saveFrontendStatsd,
  showAddToCartModal,
  showQuestionsAiModal,
  updateBudgetComponents,
  itemId,
  message,
  fetchContactInfo,
  recaptchav2token,
  isFetchingData,
  onClick,
  redirectToLogin,
  doContactAction,
  featureName,
  recaptchaSiteKey,
  shopId,
  triggerBuyNowOnePayForAll,
}) => {
  const { isWebview, deviceType } = useContext(StaticPropsContext);
  return (
    <div
      className={classNames(`${namespace}__container`, {
        [`${namespace}__container--one-element`]: actions.length === 1,
        [`${namespace}__container--featured`]: content_featured,
      })}
    >
      {actions.map(action => {
        const { action_type, disabled, disabled_text, label, type, tooltip } = action;
        const actionKey = generateKeyToComponent(action);
        const actionProps = {
          triggerBuyNowOnePayForAll,
          key: label.text,
          size,
          content_featured,
          hierarchy: getActionHierarchy(type),
          fallbackUrl: action.fallback,
          ...action,
          onSubmit,
          deviceType,
          showQuantityError,
          showVariationsError,
          showAddToCartModal,
          triggerSequencer,
          showQuestionsAiModal,
          updateBudgetComponents,
          actionType: action_type,
          itemId,
          recaptchav2token,
          recaptchaSiteKey,
          message,
          fetchContactInfo,
          isFetchingData,
          saveFrontendStatsd,
          tooltip:
            disabled && disabled_text
              ? {
                  content: disabled_text,
                  mouseLeaveDelay: -0.8,
                  offsetY: -8,
                  ...mapOffsetsToCamelCase(action.tooltip || {}),
                }
              : null,
          onClick: action_type === 'SCROLL_TO' ? onClick : null,
          redirectToLogin,
          doContactAction,
          featureName,
          loginType: action_type === 'LINK' ? 'question' : 'vis_motor_contact',
          ariaDisabled: false,
          featuredInfo: tooltip,
          shopId,
        };
        return <Action key={actionKey} {...actionProps} />;
      })}
      {form &&
        Object.keys(form).map(key => {
          // TODO: Esto lo deberia hacer el backend
          // dado a que estan asistiendo a nativo en otras cosas con mayor prioridad lo tomamos nosotros
          if (isWebview && key === 'item_id') {
            return (
              <>
                <input type="hidden" key="ids" name="ids" value={`${form.item_id}-${selectedQuantity}`} />
                <input type="hidden" key="items" name="items" value={`${form.item_id}-${selectedQuantity}`} />
              </>
            );
          }

          return <input type="hidden" key={key} name={key} value={form[key]} />;
        })}
      <input type="hidden" key="quantity" name="quantity" value={selectedQuantity} />
    </div>
  );
};

Actions.propTypes = {
  itemId: string,
  message: shape({
    text: string,
    type: string,
    show: bool,
    key: number,
  }),
  actions: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      type: string.isRequired,
      target: string,
      track: shape({}),
      tooltip: shape({}),
    }),
  ).isRequired,
  form: shape(),
  selectedQuantity: number,
  size: string,
  content_featured: bool,
  onSubmit: func,
  triggerBuyNowOnePayForAll: func,
  triggerSequencer: func,
  showQuantityError: func,
  showVariationsError: func,
  updateBudgetComponents: func,
  fetchContactInfo: func,
  recaptchav2token: string,
  recaptchaSiteKey: string,
  isFetchingData: bool,
  onClick: func,
  doContactAction: arrayOf(
    shape({
      origin: string,
    }),
  ),
  redirectToLogin: func,
  featureName: string,
  shopId: number,
  showAddToCartModal: func,
  showQuestionsAiModal: func,
  saveFrontendStatsd: func,
};

Actions.defaultProps = {
  itemId: null,
  message: null,
  size: null,
  content_featured: false,
  onSubmit: null,
  selectedQuantity: 1,
  triggerBuyNowOnePayForAll: null,
  triggerSequencer: null,
  showQuantityError: null,
  showVariationsError: null,
  updateBudgetComponents: null,
  saveFrontendStatsd: null,
  featureName: null,
  form: null,
  fetchContactInfo: null,
  recaptchav2token: null,
  recaptchaSiteKey: null,
  isFetchingData: false,
  onClick: null,
  redirectToLogin: null,
  doContactAction: null,
  shopId: null,
  showAddToCartModal: null,
  showQuestionsAiModal: null,
};

const ActionsBuybox = props => {
  const { className, onSubmit } = props;
  return (
    <AndesForm className={classNames(namespace, className)} method="get" onSubmit={onSubmit}>
      <Actions {...props} />
    </AndesForm>
  );
};

ActionsBuybox.propTypes = {
  className: string,
  onSubmit: func,
};

ActionsBuybox.defaultProps = {
  className: null,
  onSubmit: null,
};

module.exports = React.memo(ActionsBuybox);
