const url = require('url');
const idToUrl = require('../../../lib/id-to-url');
const { DECORATION_SELECTED } = require('../utils/types');

const jsonToProps = ({ pickers, picture_config, ...rest }) => {
  const {
    template,
    template_2x: template2x,
    template_thumbnail: templateThumbnail,
    template_thumbnail_2x: templateThumbnail2x,
  } = picture_config;

  pickers.forEach(picker => {
    picker.products.forEach(product => {
      if (!product.permalink && picker.permalink && product.attribute_id) {
        product.permalink = picker.permalink.replace('{id}', product.attribute_id);
      }
      // Delete host from pathname using url.path
      if (product.permalink) {
        const permaUrl = url.parse(product.permalink);
        product.permalink = permaUrl.path;
      }

      if (!product.selected) {
        product.selected = product.decoration_type === DECORATION_SELECTED;
      }

      if (product.picture && product.picture.url) {
        product.picture = {
          src: product.picture.url.src,
          src2x: product.picture.url.src2x ? product.picture.url.src2x : null,
        };
      } else if (product.picture) {
        product.picture = {
          ...product.picture,
          src: idToUrl(product.picture.id, template),
          src2x: template2x ? idToUrl(product.picture.id, template2x) : null,
          thumbnail: {
            src: templateThumbnail ? idToUrl(product.picture.id, templateThumbnail) : null,
            src2x: templateThumbnail2x ? idToUrl(product.picture.id, templateThumbnail2x) : null,
          },
        };
      }
    });
  });

  return { pickers, ...rest };
};

module.exports = jsonToProps;
