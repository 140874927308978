const React = require('react');
const { useRef } = require('react');
const { bool, string, oneOf, shape, func, node } = require('prop-types');
const classnames = require('classnames');
const { Placeholder, Line } = require('../../../placeholder');
const Thumbnail = require('../../components/thumbnail-tooltip.mobile');
const useScrollElement = require('../../../../hooks/use-scroll-element');
const { DECORATION_BLOCKED } = require('../../utils/types');

const namespace = 'ui-pdp-variations__modal__variant-large';

const VariantLarge = React.forwardRef(({ has_details, picture, product, productDetails, onClick, isFetching }, ref) => {
  const isBlocked = product.decoration_type === DECORATION_BLOCKED;
  const thumbnailRef = useRef();

  const scrollToElement = useScrollElement({
    container: ref,
    element: thumbnailRef,
    shouldScroll: product.selected || product.isFirstAvailable,
  });

  return (
    <Thumbnail
      dataTestId={`variation-large-${product.id}`}
      ref={thumbnailRef}
      key={product.id}
      className={classnames(namespace, {
        [`${namespace}--shrunk`]: !picture,
        [`${namespace}--medium`]: !has_details,
      })}
      blocked={isBlocked}
      modifier={product.decoration_type}
      picture={picture ? { ...picture, src: picture.src2x || picture.src, src2x: null } : null}
      href={product.permalink}
      onClick={e => {
        if (!isBlocked) {
          scrollToElement();
          onClick(e);
        }
      }}
    >
      {!has_details && isFetching ? (
        <Placeholder>
          <Line height="11px" />
        </Placeholder>
      ) : (
        product.label.text && <p className={`${namespace}__text`}>{product.label.text}</p>
      )}
      {productDetails}
    </Thumbnail>
  );
});

VariantLarge.propTypes = {
  isFetching: bool,
  has_details: bool,
  picture: shape({
    alt: string,
    id: string,
    src: string.isRequired,
    src2x: string,
  }),
  product: shape({
    id: string,
    decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
    label: shape({
      color: string,
      text: string,
    }),
    permalink: string.isRequired,
    picture: shape({
      id: string,
    }),
  }).isRequired,
  productDetails: node,
  onClick: func.isRequired,
};

VariantLarge.defaultProps = {
  has_details: false,
  isFetching: false,
  picture: null,
  productDetails: null,
  ariaLabel: null,
};

module.exports = VariantLarge;
