// TODO-PRELOAD-REFACTOR: REMOVE THIS:
const DEPRECATED_MSG_LOADED = 'vpp:iframe-modal:loaded';
const DEPRECATED_MSG_CLOSE = 'vpp:iframe-modal:close';
const DEPRECATED_MSG_SNACKBAR = 'vpp:iframe-modal:snackbar';
const DEPRECATED_MSG_NAVIGATE = 'vpp:iframe-modal:navigate';
const DEPRECATED_MSG_REDIRECT = 'vpp:iframe-modal:redirect';
// TODO-PRELOAD-REFACTOR: LEAVE THIS:
const MSG_LOADED = 'vpp:on-demand-iframe:loaded';
const MSG_CLOSE = 'vpp:on-demand-iframe:close';
const MSG_CLOSABLE = 'vpp:on-demand-iframe:closable';
const MSG_SNACKBAR = 'vpp:on-demand-iframe:snackbar';
const MSG_NAVIGATE = 'vpp:on-demand-iframe:navigate';
const MSG_REDIRECT = 'vpp:on-demand-iframe:redirect';
const MSG_ASK_SELLER = 'vpp:on-demand-iframe:ask-seller';

const eventTypes = {
  // TODO-PRELOAD-REFACTOR: REMOVE THIS:
  DEPRECATED_MSG_LOADED,
  DEPRECATED_MSG_CLOSE,
  DEPRECATED_MSG_SNACKBAR,
  DEPRECATED_MSG_NAVIGATE,
  DEPRECATED_MSG_REDIRECT,
  // TODO-PRELOAD-REFACTOR: LEAVE THIS:
  MSG_LOADED,
  MSG_CLOSE,
  MSG_CLOSABLE,
  MSG_SNACKBAR,
  MSG_NAVIGATE,
  MSG_REDIRECT,
  MSG_ASK_SELLER,
};

const KEY_TRACKS = {
  INITIAL_LOAD: 'initial_load',
  WEBVIEW_EVENT: 'webview_event',
};

module.exports = {
  eventTypes,
  KEY_TRACKS,
};
