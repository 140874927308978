const React = require('react');
const { useRef } = require('react');
const { string, oneOf, shape, func, number } = require('prop-types');
const Thumbnail = require('../../components/thumbnail-tooltip.mobile');
const useScrollElement = require('../../../../hooks/use-scroll-element');
const { DECORATION_BLOCKED } = require('../../utils/types');

const namespace = 'ui-pdp-variations__box__variant-short';

const VariationsShortVariant = React.forwardRef(({ product, onClick, minWidth, maxWidth }, ref) => {
  const isBlocked = product.decoration_type === DECORATION_BLOCKED;
  const id = product.attribute_id ? product.attribute_id : product.id;
  const thumbnailRef = useRef();

  const scrollToElement = useScrollElement({
    container: ref,
    element: thumbnailRef,
    shouldScroll: product.selected || product.isFirstAvailable,
  });

  return (
    <Thumbnail
      dataTestId={`variation-short-${product.id}`}
      ref={thumbnailRef}
      key={id}
      label={product.label.text}
      subtitle={product.subtitle}
      className={namespace}
      blocked={isBlocked}
      modifier={product.decoration_type}
      href={product.permalink}
      onClick={e => {
        if (!isBlocked) {
          scrollToElement();
          onClick(e);
        }
      }}
      minWidth={minWidth}
      maxWidth={maxWidth}
    />
  );
});

VariationsShortVariant.propTypes = {
  maxWidth: number,
  minWidth: number,
  product: shape({
    permalink: string.isRequired,
    id: string,
    decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
    label: shape({
      color: string,
      text: string,
    }),
    picture: shape({
      id: string,
    }),
  }).isRequired,
  onClick: func.isRequired,
};

module.exports = VariationsShortVariant;
