const React = require('react');
const { useState } = require('react');
const { shape, string, arrayOf, number, bool } = require('prop-types');
const classnames = require('classnames');

const StyledLabel = require('../styled-label');
const BackdropModal = require('../backdrop-modal');
const RatingLevels = require('../rating-levels');
const Media = require('../media');
const IconFactory = require('../icons/factory-builder')();

const applyClasses = require('../../lib/apply-classes');
const componentEnhance = require('../../lib/component-enhance');

const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-vpp-apparel-size';

const ApparelSizeSpecs = ({
  state,
  size_chart,
  fit_as_expected_action,
  reviews_attribute_summary,
  isOutsideVariations,
  reviews_attribute_title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (state === 'HIDDEN' || (!size_chart && !fit_as_expected_action)) {
    return null;
  }

  return (
    <div
      className={classnames(
        namespace,
        { '__only-fit-as-expected': !size_chart },
        { '__outside-variations': isOutsideVariations },
      )}
    >
      {size_chart && (
        <Media>
          <a href={size_chart.target} className={`${namespace}__sizes`} data-testid="apparel-size-specs__sizes">
            {IconFactory({ id: 'ruler' }, `${namespace}__icon-ruler`)}
            <StyledLabel className={`${namespace}__label`} {...size_chart.label} as="span" />
          </a>
        </Media>
      )}
      {fit_as_expected_action && (
        <>
          <button
            type="button"
            className={classnames(
              `${namespace}__fit-as-expected`,
              applyClasses({ color: fit_as_expected_action.label.color, size: 'XSMALL' }),
            )}
            onClick={() => {
              setIsModalOpen(true);
              trackEvent(fit_as_expected_action.track);
            }}
            data-testid="apparel-size-specs__fit-as-expected-button"
          >
            {componentEnhance.jsx(fit_as_expected_action.label.text, fit_as_expected_action.label.values)}
            {IconFactory({ id: 'chevron' }, `${namespace}__fit-as-expected__chevron ui-pdp-icon--chevron-down`)}
          </button>
          <BackdropModal
            onClose={() => setIsModalOpen(false)}
            visible={isModalOpen}
            url="#fit-as-expected"
            className={classnames(`${namespace}__fit-as-expected__modal`)}
            closeModalLabel={size_chart?.close_modal_label}
          >
            <RatingLevels title={reviews_attribute_title} levels={reviews_attribute_summary} />
          </BackdropModal>
        </>
      )}
    </div>
  );
};

ApparelSizeSpecs.propTypes = {
  fit_as_expected_action: shape({}),
  isOutsideVariations: bool,
  state: string.isRequired,
  size_chart: shape({}),
  reviews_attribute_summary: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
      }).isRequired,
      percentage: number.isRequired,
      value: number.isRequired,
      value_label: shape({
        text: string.isRequired,
      }).isRequired,
    }),
  ),
  closeModalLabel: string.isRequired,
  reviews_attribute_title: shape({
    text: string.isRequired,
    color: string.isRequired,
    font_size: string.isRequired,
    font_family: string.isRequired,
    values: shape({
      value: shape({
        text: string.isRequired,
        color: string.isRequired,
        font_size: string.isRequired,
        font_family: string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

ApparelSizeSpecs.defaultProps = {
  fit_as_expected_action: null,
  isOutsideVariations: false,
  size_chart: null,
  reviews_attribute_summary: null,
  reviews_attribute_title: null,
};

module.exports = ApparelSizeSpecs;
