const React = require('react');
const { arrayOf, func, bool, oneOf, string, shape } = require('prop-types');
const { Notification } = require('@andes/badge');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');

const { getIcon } = require('../../action-row/icon-helper');
const VariationsLargeVariantBox = require('./variants/variations-large-variant-box');
const VariationsShortVariantBox = require('./variants/variations-short-variant-box');
const VariationsSmallPlaceholder = require('../components/variations-small-placeholder');
const ProductDetails = require('../components/product-details');
const componentEnhance = require('../../../lib/component-enhance');

const getPictureConfig = require('../lib/get-picture-config');
const { trackEvent } = require('../../../lib/tracking');

const namespace = 'ui-pdp-variations';
const { PriceFontSize } = require('../../../lib/num-font-size-enum');

const { DECORATION_NONE } = require('../utils/types');
const ApparelSizeSpecs = require('../../apparel-size-specs/apparel-size-specs.mobile');
const StyledLabel = require('../../styled-label');

const getSelectedProduct = picker => picker.products.find(product => product.selected);

const handleGalleryChange = ({ src, src2x }) => {
  if (src) {
    // TODO FIXME, este método de buscar la activa no es ideal, debemos usar algo más específico.
    const activeSlide = document.querySelector('.andes-carousel-snapped__slide--active')?.firstChild?.firstChild;
    if (activeSlide) {
      activeSlide.setAttribute('src', src);
      if (src2x) {
        activeSlide.setAttribute('srcset', src2x);
      }
    }
  }
};

const handleOnClickVariation = (product, pickerId, selectVariations, handleUpdate, isFetching, from) => {
  const attributesToReset = product.attributes || [];
  if (isFetching) {
    return;
  }

  const showSkeleton =
    from === 'shortVariation' || (from === 'largeVariation' && product.decoration_type === 'DISABLED');

  if (!product.selected) {
    selectVariations(pickerId, product.id, product.attribute_id || product.label.text);
    if (product.picture) {
      handleGalleryChange(product.picture);
    }
    handleUpdate(product.id, pickerId, product.attribute_id, product.permalink, attributesToReset, showSkeleton);
    trackEvent(product.track);
  }
};

const renderLargeVariant = (
  product,
  pickerId,
  has_details,
  isFetching,
  selectVariations,
  scrollableContainerRef,
  handleUpdate,
) => {
  let productDetails;
  let detailsQuantity = 0;
  if (has_details) {
    if (product.price) {
      detailsQuantity += 1;
    }
    if (product.stock) {
      detailsQuantity += 1;
    }
    productDetails = <ProductDetails {...product} num_font_size={PriceFontSize.VARIATION_BOX} modifier="box__stock" />;
  }

  return (
    <VariationsLargeVariantBox
      ref={scrollableContainerRef}
      onClick={e => {
        e.preventDefault();
        handleOnClickVariation(product, pickerId, selectVariations, handleUpdate, isFetching, 'largeVariation');
      }}
      isFetching={isFetching}
      product={product}
      has_details={has_details}
      productDetails={
        has_details && isFetching ? (
          <VariationsSmallPlaceholder rows={detailsQuantity} className="ui-pdp-variations__box__variant-large__line" />
        ) : (
          productDetails
        )
      }
      picture={getPictureConfig(product)}
    />
  );
};

const renderShortVariant = (
  product,
  pickerId,
  selectVariations,
  scrollableContainerRef,
  handleUpdate,
  isFetching,
  minWidth,
  maxWidth,
) => (
  <VariationsShortVariantBox
    ref={scrollableContainerRef}
    product={product}
    onClick={e => {
      e.preventDefault();
      handleOnClickVariation(product, pickerId, selectVariations, handleUpdate, isFetching, 'shortVariation');
    }}
    minWidth={minWidth}
    maxWidth={maxWidth}
  />
);

const renderSelectedSubtitleRow = row => (
  <StyledLabel text={componentEnhance.jsx(row.text, row.values)} className={`${namespace}__selected-subtitle-row`} />
);

const VariationsBoxContent = ({
  id,
  lastAttributeSelected,
  updateComponents,
  selected_attributes,
  pickers,
  initFetchVariations,
  updateVariationsBox,
  isFetching,
  selectVariations,
  isErrorVariation,
  shouldShakeAnimation,
}) => {
  const handleUpdate = (productId, pickerId, attrId, permalink, attributesToReset, showSkeleton) => {
    let selectedProductHistoryData = null;
    const pId = productId || id;
    if (pId) {
      for (let x = 0; x < pickers.length; x += 1) {
        const picker = pickers[x];
        for (let y = 0; y < picker.products.length; y += 1) {
          const product = picker.products[y];
          if (
            (!lastAttributeSelected && product.id === pId) ||
            (lastAttributeSelected && product.attribute_id === lastAttributeSelected)
          ) {
            // eslint-disable-next-line max-depth
            if (selected_attributes && selected_attributes[pickerId]) {
              delete selected_attributes[pickerId];
            }
            // eslint-disable-next-line max-depth
            if (selected_attributes) {
              attributesToReset.forEach(attribute => {
                if (selected_attributes[attribute]) {
                  delete selected_attributes[attribute];
                }
              });
            }
            selectedProductHistoryData = {
              path: permalink,
              state: { productId: pId },
            };
          }
        }
      }

      if (showSkeleton) {
        initFetchVariations();
      }
      updateComponents(
        pId,
        { ...selected_attributes, [pickerId]: attrId },
        selectedProductHistoryData,
        updateVariationsBox,
        attrId,
      );
    }
  };

  const isAvailable = product => product.decoration_type === DECORATION_NONE;

  pickers.forEach(picker => {
    if (picker.products.some(isAvailable) && !picker.products.find(product => product.selected)) {
      picker.products.find(isAvailable).isFirstAvailable = true;
    }
  });

  return (
    <div className={`${namespace}__box`}>
      {// eslint-disable-next-line complexity
      pickers.map((picker, index) => {
        const scrollableContainerRef = React.createRef();
        return (
          <React.Fragment key={picker.id}>
            {index !== 0 && <hr className={`${namespace}__box__variation-separator`} />}
            <div className={`${namespace}__box__variation`} key={picker.id}>
              <div className={picker.id === 'SIZE' && `${namespace}__label-with-sizechart`}>
                {picker.label && (
                  <p
                    className={classnames(
                      {
                        [`${colornames(picker.error_message && picker.error_message.label.color)}`]:
                          isErrorVariation && picker.error_message && !getSelectedProduct(picker),
                      },
                      {
                        [`${namespace}__box__label--shake`]:
                          shouldShakeAnimation &&
                          isErrorVariation &&
                          picker.error_message &&
                          !getSelectedProduct(picker),
                      },
                      {
                        [`${namespace}__box__label--with-subtitle`]: picker.selected_subtitle,
                      },
                      `${namespace}__box__label`,
                    )}
                  >
                    {picker.label.text}
                    {isErrorVariation && picker.error_message && !getSelectedProduct(picker) ? (
                      <div className={`${namespace}__box__error_message`}>
                        <Notification type="error" size="small" />
                        <p className={`${namespace}__box__error_message__label`}>
                          {picker.error_message.selected_option.text}
                        </p>
                      </div>
                    ) : (
                      picker.selected_option && (
                        <span className={`${namespace}__box__selected`}>
                          {picker.selected_option.text_icon && getIcon(picker.selected_option.text_icon.id)}
                          <span className={`${namespace}__selected ui-pdp-color--${picker.selected_option.color}`}>
                            {picker.selected_option.text}
                          </span>
                        </span>
                      )
                    )}
                  </p>
                )}
                {picker.id === 'SIZE' && picker.action && (
                  <ApparelSizeSpecs state="VISIBLE" size_chart={picker.action} isOutsideVariations />
                )}
              </div>
              {picker.selected_subtitle && (
                <p className={classnames(`${namespace}__box__subtitle`, colornames(picker.selected_subtitle))}>
                  {picker.selected_subtitle.text}
                </p>
              )}
              {!picker.show_only_label_picker && (
                <div className={`${namespace}__box__variants`} ref={scrollableContainerRef}>
                  <ul className={`${namespace}__box__list`}>
                    {picker.products.map(product => (
                      <li key={product.attribute_id} className={`${namespace}__box__variant`}>
                        {picker.has_details || (picker.thumbnail || product.picture)
                          ? renderLargeVariant(
                              product,
                              picker.id,
                              picker.has_details,
                              isFetching,
                              selectVariations,
                              scrollableContainerRef,
                              handleUpdate,
                            )
                          : renderShortVariant(
                              product,
                              picker.id,
                              selectVariations,
                              scrollableContainerRef,
                              handleUpdate,
                              isFetching,
                              picker.min_width,
                              picker.max_width,
                            )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {picker.selected_subtitle_rows && picker.selected_subtitle_rows.length !== 0 && (
                <div className={`${namespace}__subtitle-rows-container`}>
                  {picker.selected_subtitle_rows.map(row => renderSelectedSubtitleRow(row))}
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

VariationsBoxContent.propTypes = {
  id: string.isRequired,
  lastAttributeSelected: string,
  selected_attributes: shape({}),
  pickers: arrayOf(
    shape({
      id: string,
      hasDetails: bool,
      label: shape({
        color: string,
        text: string,
      }),
      products: arrayOf(
        shape({
          id: string,
          decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
          label: shape({
            color: string,
            text: string,
          }),
          picture: shape({
            id: string,
          }),
        }),
      ),
    }),
  ).isRequired,
  updateComponents: func.isRequired,
  updateVariationsBox: func.isRequired,
  initFetchVariations: func.isRequired,
  isFetching: bool,
  selectVariations: func.isRequired,
  isErrorVariation: bool,
  shouldShakeAnimation: bool,
};

VariationsBoxContent.defaultProps = {
  lastAttributeSelected: null,
  isFetching: false,
  selected_attributes: null,
  variationsDetails: null,
  actionSelected: {},
  buttonQuantity: null,
  isErrorVariation: false,
  shouldShakeAnimation: false,
  variationId: null,
  selectQuantity: null,
};

module.exports = VariationsBoxContent;
